<template>
  <base-layout title="404 Not-Found">
    <ion-grid>
      <ion-row class="push-down">
        <ion-col
          size="12"
          size-md="6"
          offset-md="3"
          size-lg="4"
          offset-lg="4"
          
        >
          <h3 class="ion-text-center">Sorry, this page does not exist!</h3>

          <ion-button
            expand="full"
            color="warning"
            router-direction="root"
            :router-link="{ name: 'dashboard' }"
          >
            Go Back
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>
<script>
import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/vue";
export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,

    IonButton,
  },
};
</script>
<style scoped>
.push-down {
  margin-top: 30vh;
}

h3 {
  margin-bottom: 2rem;
}
</style>